import React, { Fragment, Component } from "react";
import { connetParams, url } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assistant_id: '',
            assistants: Array(),
            from: '',
            to: '',
            loading: false,
            assistants: Array()
        }
    }
    componentDidMount() {
        let newHeaders = new Headers();
        newHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const param = {
            method: 'GET',
            headers: newHeaders,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant',
            param
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        assistants: data.assistant
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    download = () => {
        this.setState({
            loading: true
        })
        const load = () => {
            this.setState({
                loading: false
            })
        }
        setTimeout(load, 2000);
    }
    render() {
        const { 
            loading, 
            assistants, 
            from, 
            to, 
            assistant_id
        } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Descargar conversaciones</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/conversations"}>Conversaciones</Link></li>
                            <li className="breadcrumb-item active">Descargar conversaciones</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="mb-3 col-6">
                                            <label for="from" className="col-form-label">Desde:</label>
                                            <input type="date" max={new Date().toJSON().slice(0, 10)} onChange={e => this.setState({ from: e.target.value })} className="form-control" id="from" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label for="to" className="col-form-label">Hasta:</label>
                                            <input type="date" onChange={e => this.setState({ to: e.target.value })} className="form-control" id="to" />
                                        </div>
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label for="assistants" className="col-form-label">Asistente:</label>
                                        <select className="form-control" id="assistants" onChange={e => this.setState({ assistant_id: e.target.value })}>
                                            <option selected>Seleccione uno</option>
                                            {
                                                assistants.map(assistant => {
                                                    return <option value={assistant._id}>{assistant.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <a className="btn btn-primary"
                                            onClick={this.download}
                                            href={url + 'conversations/download/' + assistant_id + '?_from=' + from + ' 00:00:00' + '&to=' + to + ' 23:59:59'}
                                            type="button">Descargar</a>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function Download() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default Download;