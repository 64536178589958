import React, { Component } from "react";
import { connetParams } from "../../../utils/Connect";
import { getCookie, eraseCookie } from "../../../utils/Cookies";
import parse from 'html-react-parser'


class NegatveInsights extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            response: 0,
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie("token"));
        const params = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        connetParams(
            'chart/insights_negative',
            params
        )
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                if (data["detail"] === "Invalid credentials") {
                    eraseCookie("token");
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        response: data.response,
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error.message);
            });

    }
    render() {
        const { response, loading } = this.state;
        if (loading) {
            return <div className="d-grid gap-2 col-12 mx-auto">
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        }
        else return parse(response)
    }
}

export default NegatveInsights;