import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            assistant_id: '',
            is_associate: false,
            prompt: '',
            model: '',
            temperature: 0.0,
            loading: false
        }
    }
    createAssistant = () => {
        this.setState({ loading: true })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "name": this.state.name,
            "description": this.state.description,
            'openai_id': this.state.assistant_id,
            'is_associate': this.state.is_associate,
            'prompt': this.state.prompt,
            'model': this.state.model,
            "temperature": this.state.temperature
        });

        let payload = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/super_assistant');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { loading, is_associate } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Crear Asistente</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/super_assistant"}>Asistentes</Link></li>
                            <li className="breadcrumb-item active">Crear asistente</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Nombre:</label>
                                        <input type="text" onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="description" className="col-form-label">Descripcion:</label>
                                        <textarea className="form-control" onChange={e => this.setState({ description: e.target.value })} id="description"></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label for="prompt" className="col-form-label">Prompt:</label>
                                        <textarea className="form-control" id="prompt"
                                            onChange={e => this.setState({ prompt: e.target.value })}
                                            rows="15"></textarea>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-6">
                                            <label for="model" className="col-form-label">Modelo:</label>
                                            <select className='form-control'
                                                id="model"
                                                onChange={e => this.setState({ model: e.target.value })}
                                                aria-label="Default select example">
                                                <option selected>Seleccione uno</option>
                                                <option value={'gpt-4o'}>gpt-4o</option>
                                                <option value={'gpt-4-turbo-preview'}>gpt-4-turbo-preview</option>
                                                <option value={'gpt-3.5-turbo-0125'}>gpt-3.5-turbo-0125</option>
                                            </select>
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label for="temperature" className="col-form-label">Temperatura:</label>
                                            <input type="number" onChange={e => this.setState({ temperature: e.target.value })} className="form-control" id="temperature" />
                                        </div>
                                    </div>

                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary" onClick={this.createAssistant} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function AssociateAssistant() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default AssociateAssistant;