import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            template: '',
            type: getCookie('type'),
            loading: false
        }
    }
    send_campaign = () => {
        if (this.state.template === '' || this.state.file === '') {
            alert('Falta arhivo y/o el texto')
        } else {
            this.setState({
                loading: true
            });
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + getCookie('token'));
    
            const formdata = new FormData();
    
            formdata.append("template", this.state.template);
            formdata.append("file", this.state.file);
    
            let payload = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            connetParams(
                'twilio/',
                payload
            ).then(response => response.text())
                .then(result => {
                    const data = JSON.parse(result);
                    if (data['detail'] === 'Invalid credentials') {
                        eraseCookie('token');
                        this.props.navigate('/');
                    }
                    if (!data.error) {
                        alert('Campaña enviada');
                    }
                    else{
                        alert('Error al enviar la campaña');
                    }
                    this.setState({loading: false});
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error.message);
                });
        }
    }
    render() {
        const { loading } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Campañas</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to={"/campaigns"}>Campañas</Link></li>
                            <li className="breadcrumb-item active">Campañas</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Crear Campaña
                            </div>
                            <div className="card-body">
                                {
                                    loading ? (
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <form>
                                                <div className="mb-3">
                                                    <label for="file" className="col-form-label">Archivo:</label>
                                                    <input type="file" onChange={e => this.setState({ file: e.target.files[0] })} className="form-control" id="file" />
                                                </div>
                                                <div className="mb-3">
                                                    <label for="template" className="col-form-label">Template:</label>
                                                    <textarea onChange={e => this.setState({ template: e.target.value })} className="form-control" id="template"></textarea>
                                                </div>
                                            </form>
                                            <hr></hr>
                                            <div className="d-grid gap-2 col-6 mx-auto">
                                                {
                                                    loading ? (
                                                        <div className="d-flex justify-content-center">
                                                            <div className="spinner-grow text-primary" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <button disabled className="btn btn-primary" onClick={this.send_campaign} type="button">Enviar Campaña</button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function SendCampaign() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default SendCampaign;