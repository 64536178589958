import React, { Fragment, Component } from "react";
import { connetParams } from "../../../utils/Connect";
import { useParams } from "react-router-dom";
import { eraseCookie, getCookie } from '../../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index_id: this.props.data.index_id,
            name: '',
            docs: Array(),
            loading: false
        }
    }
    componentDidMount(){
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'embeddings/docs/' + this.state.index_id,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        docs: data.docs
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    render() {
        const {docs, index_id, loading} = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">{index_id}</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to={"/documents"}>Documentos</Link></li>
                            <li className="breadcrumb-item active">{index_id}</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Tablas de documentos
                            </div>
                            <div className="card-body">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <Link
                                        to={'/documents/create/' + index_id}
                                        className="btn btn-primary me-md-2"
                                    >
                                        Subir documento
                                    </Link>
                                </div>
                                {
                                    loading ? (
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Fecha</th>
                                                    <th scope="col-5"></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    docs.map(doc => {
                                                        return (
                                                            <tr>
                                                                <td>{doc.name}</td>
                                                                <td>{doc.created_at}</td>
                                                                <td>
                                                                    <a className="link-danger" type="submit"
                                                                        onClick={() => this.delete(doc._id)}
                                                                    >
                                                                        <i className="fa-solid fa-trash m-1"></i>
                                                                    </a >
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>

                                        </table>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function IndexDocument() {
    let navigate = useNavigate();
    return <Element data={useParams()} navigate={navigate} />
}

export default IndexDocument;