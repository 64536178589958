import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            doc_id: '',
            indexes: Array(),
            customer_id: '',
            prompt: '',
            model: '',
            type: 'sherlock',
            endpoint: '',
            have_prompt: false,
            temperature: 0.0,
            customers: Array(),
            loading: false
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'customers',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (!data.error) {
                    this.setState({
                        customers: data.customers,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
        connetParams(
            'embeddings/index?all=' + true,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (!data.error) {
                    this.setState({
                        indexes: data.index,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    createTool = () => {
        this.setState({ loading: true })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "name": this.state.name,
            "description": this.state.description,
            'customer_id': this.state.customer_id,
            'prompt': this.state.prompt,
            'model': this.state.model,
            'index_id': this.state.doc_id,
            "temperature": this.state.temperature,
            "type": this.state.type,
            "endpoint": this.state.endpoint,
            "have_prompt": this.state.have_prompt
        });

        let payload = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'tool',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/tools');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    have_prompt = () => {
        const have_prompt = this.state.have_prompt;
        this.setState({
            have_prompt: have_prompt ? false : true
        })
    }
    render() {
        const { loading, customers, indexes, type, have_prompt } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Crear herramienta</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/tools"}>Herramientas</Link></li>
                            <li className="breadcrumb-item active">Crear herramienta</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Nombre:</label>
                                        <input type="text" onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="description" className="col-form-label">Descripcion:</label>
                                        <textarea className="form-control" onChange={e => this.setState({ description: e.target.value })} id="description"></textarea>
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label for="type" className="col-form-label">Tipo:</label>
                                        <select className='form-control'
                                            id="type"
                                            onChange={e => this.setState({ type: e.target.value })}
                                            value={type}
                                            aria-label="Default select example">
                                            <option selected>Seleccione uno</option>
                                            <option value={'sherlock'}>Sherlock</option>
                                            <option value={'rest'}>REST</option>
                                        </select>
                                    </div>
                                    {
                                        type == 'sherlock' ?
                                            <>
                                                <div className="mb-3">
                                                    <label for="prompt" className="col-form-label">Prompt:</label>
                                                    <textarea className="form-control" id="prompt"
                                                        onChange={e => this.setState({ prompt: e.target.value })}
                                                        rows="15"></textarea>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-6">
                                                        <label for="model" className="col-form-label">Modelo:</label>
                                                        <select className='form-control'
                                                            id="model"
                                                            onChange={e => this.setState({ model: e.target.value })}
                                                            aria-label="Default select example">
                                                            <option selected>Seleccione uno</option>
                                                            <option value={'gpt-4o'}>gpt-4o</option>
                                                            <option value={'gpt-4-turbo-preview'}>gpt-4-turbo-preview</option>
                                                            <option value={'gpt-3.5-turbo-0125'}>gpt-3.5-turbo-0125</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-3 col-6">
                                                        <label for="temperature" className="col-form-label">Temperatura:</label>
                                                        <input type="number" onChange={e => this.setState({ temperature: e.target.value })} max={2} min={0} className="form-control" id="temperature" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="mb-3 col-6">
                                                        <label for="customer_id" className="col-form-label">Cliente:</label>
                                                        <select className='form-control' id="customer_id" onChange={e => this.setState({ customer_id: e.target.value })} aria-label="Default select example">
                                                            <option selected>Seleccione uno</option>
                                                            {
                                                                customers.map(customer => {
                                                                    return <option value={customer._id}>{customer.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="mb-3 col-6">
                                                        <label for="doc_id" className="col-form-label">Index:</label>
                                                        <select className='form-control' id="doc_id"
                                                            onChange={e => this.setState({ doc_id: e.target.value })}
                                                            aria-label="Default select example">
                                                            <option selected>Seleccione uno</option>
                                                            {
                                                                indexes.map(doc => {
                                                                    return <option value={doc._id}>{doc.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="mb-3">
                                                    <label for="endpoint" className="col-form-label">Endpoint:</label>
                                                    <input type="text" onChange={e => this.setState({ endpoint: e.target.value })} className="form-control" id="endpoint" />
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input"
                                                        type="checkbox"
                                                        value={have_prompt}
                                                        onChange={this.have_prompt} id="have_prompt" />
                                                    <label class="form-check-label" for="have_prompt">
                                                        tiene prompt?
                                                    </label>
                                                </div>
                                                {
                                                    have_prompt ?
                                                        <>
                                                            <div className="mb-3">
                                                                <label for="prompt" className="col-form-label">Prompt:</label>
                                                                <textarea className="form-control" id="prompt"
                                                                    onChange={e => this.setState({ prompt: e.target.value })}
                                                                    rows="15"></textarea>
                                                            </div>
                                                            <div className="row">
                                                                <div className="mb-3 col-6">
                                                                    <label for="model" className="col-form-label">Modelo:</label>
                                                                    <select className='form-control'
                                                                        id="model"
                                                                        onChange={e => this.setState({ model: e.target.value })}
                                                                        aria-label="Default select example">
                                                                        <option selected>Seleccione uno</option>
                                                                        <option value={'gpt-4o'}>gpt-4o</option>
                                                                        <option value={'gpt-4-turbo-preview'}>gpt-4-turbo-preview</option>
                                                                        <option value={'gpt-3.5-turbo-0125'}>gpt-3.5-turbo-0125</option>
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3 col-6">
                                                                    <label for="temperature" className="col-form-label">Temperatura:</label>
                                                                    <input type="number" onChange={e => this.setState({ temperature: e.target.value })} max={2} min={0} className="form-control" id="temperature" />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="mb-3 col-6">
                                                                    <label for="customer_id" className="col-form-label">Cliente:</label>
                                                                    <select className='form-control' id="customer_id" onChange={e => this.setState({ customer_id: e.target.value })} aria-label="Default select example">
                                                                        <option selected>Seleccione uno</option>
                                                                        {
                                                                            customers.map(customer => {
                                                                                return <option value={customer._id}>{customer.name}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3 col-6">
                                                                    <label for="doc_id" className="col-form-label">Index:</label>
                                                                    <select className='form-control' id="doc_id"
                                                                        onChange={e => this.setState({ doc_id: e.target.value })}
                                                                        aria-label="Default select example">
                                                                        <option selected>Seleccione uno</option>
                                                                        {
                                                                            indexes.map(doc => {
                                                                                return <option value={doc._id}>{doc.name}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </>
                                    }
                                    <hr></hr>
                                    <div className="d-grid gap-2 col-6 mx-auto">
                                        {
                                            loading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-grow text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <button className="btn btn-primary" onClick={this.createTool} type="button">Guardar</button>
                                            )
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function CreateTool() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default CreateTool;