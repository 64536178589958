import React, { Component } from "react";
import { connetParams } from "../../../utils/Connect";
import { getCookie, eraseCookie } from "../../../utils/Cookies";

class FisrtContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            time: 0,
            from: this.props.from,
            to: this.props.to
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie("token"));
        const params = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        connetParams(
            "chart/first_contact?_from=" + this.state.from + ' 00:00:00' + '&to=' + this.state.to + ' 23:59:59',
            params
        )
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                if (data["detail"] === "Invalid credentials") {
                    eraseCookie("token");
                    this.props.navigate('/');
                }
                if (data.data != false) {
                    this.setState({
                        time: data.time_first_response,
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error.message);
            });

    }
    componentDidUpdate() {
        if (this.state.from != this.props.from || this.state.to != this.props.to) {
            this.setState({
                loading: true,
                from: this.props.from,
                to: this.props.to
            })
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + getCookie("token"));
            const params = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            connetParams(
                "chart/first_contact?_from=" + this.state.from + ' 00:00:00' + '&to=' + this.state.to + ' 23:59:59',
                params
            )
                .then((response) => response.text())
                .then((result) => {
                    const data = JSON.parse(result);
                    if (data["detail"] === "Invalid credentials") {
                        eraseCookie("token");
                        this.props.navigate('/');
                    }
                    if (data.data != false) {
                        this.setState({
                            time: data.time_first_response,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                });

        }
    }
    render() {
        const { time, loading } = this.state;
        if (loading) {
            return <div className="d-grid gap-2 col-12 mx-auto">
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        }
        else return <div className="h5 mb-0 font-weight-bold text-gray-800">{time}</div>
    }
}

export default FisrtContact;