import React, { Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { connetParams } from "../../../utils/Connect";
import { getCookie, eraseCookie } from "../../../utils/Cookies";


ChartJS.register(ArcElement, Tooltip, Legend);

class Sentiment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            positive: 0,
            negative: 0,
            neutral: 0
        }
    }

    componentDidMount() {
        let myHeaders = new Headers();
        const to = Date.parse(new Date().toJSON().slice(0, 10));
        myHeaders.append("Authorization", "Bearer " + getCookie("token"));
        const params = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        connetParams(
            "chart/sentiments",
            params
        )
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                if (data["detail"] === "Invalid credentials") {
                    eraseCookie("token");
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        positive: data.positive,
                        negative: data.negative,
                        neutral: data.neutral
                    })
                }
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    render() {
        const { 
            positive,
            negative,
            neutral
        } = this.state

        const not_data = 100 - (positive + negative + neutral)

        const data = {
            labels: [
                'Positivo',
                'Negativo',
                'Neutral',
                'No calificable'
            ],
            datasets: [
                {
                    label: '%',
                    data: [
                        positive,
                        negative,
                        neutral,
                        not_data
                    ],
                    backgroundColor: [
                        '#243a69',
                        '#5b88a5',
                        '#d4cdc5',
                        '#191013'
                    ],
                    borderWidth: 1,
                    outerHeight: 1,
                    innerHeight: 1,
                    innerWidth: 1,
                    outerWidth: 1,
                },
            ],
        };
        // return <Doughnut data={data} />
        return <Doughnut data={data} />
    }
}



export default Sentiment;