import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assistants: Array(),
            loading: true
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    assistants: data.assistant,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    render() {
        const { assistants, loading } = this.state;
        const deleteAssistant = (id) => {
            if (window.confirm('Estas seguro que quieres eliminar a este asistente? (tambien se eliminara de gpt)')) {
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + getCookie('token'));
                const params = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                connetParams(
                    'super_assistant/' + id,
                    params
                ).then(response => response.text())
                    .then(result => {
                        const data = JSON.parse(result);
                        if (data['detail'] === 'Invalid credentials') {
                            eraseCookie('token');
                            this.props.navigate('/');
                        }
                        this.componentDidMount();
                    })
                    .catch(error => {
                        console.log(error.message);
                    });
            } else {
                // Do nothing!
                console.log('cancel.');
            }
        }
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Asistentes</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Asistentes</li>
                        </ol>
                        <div className="row">
                            <div className="col-xl-3 col-md-6 mb-4">
                                <div className="card border-left-primary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Codigo para whatsapp</div>
                                                <a href="http://wa.me/+14155238886?text=join%20soldier-important" target="_blank"><div className="h5 mb-0 font-weight-bold text-gray-800">join soldier-important</div></a>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-message fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Lista de asistentes
                            </div>
                            <div className="card-body">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <Link
                                        to={'/super_assistant/associate'}
                                        className="btn btn-primary me-md-2"
                                    >
                                        Crear asistente
                                    </Link>
                                </div>
                                {
                                    loading ? (
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Descripcion</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    assistants.map(assistant => {
                                                        return (
                                                            <tr>
                                                                <td>{assistant._id}</td>
                                                                <td>{assistant.name}</td>
                                                                <td>{assistant.description}</td>
                                                                <td>
                                                                    {/* <Link
                                                                        to={'/super_assistant/' + assistant._id}
                                                                    >
                                                                        <i className="fa-solid fa-eye m-1"></i>
                                                                    </Link> */}
                                                                    <Link
                                                                        to={'/super_assistant/edit/' + assistant._id}
                                                                    >
                                                                        <i className="fa-solid fa-pen-to-square m-1"></i>
                                                                    </Link>
                                                                    <a className="link-danger" type="submit" onClick={() => deleteAssistant(assistant._id)}><i className="fa-solid fa-trash m-1"></i></a >
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function SuperAssistants() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default SuperAssistants;