import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            blip_id: '', 
            customer_id: '',
            channel_id: '',
            campaign_id: this.props.data.campaign_id,
            customers: Array(),
            loading: false
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'campaigns/' + this.state.campaign_id,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (!data.error) {
                    this.setState({
                        name: data.name,
                        blip_id: data.blip_id,
                        customer_id: data.customer_id,
                        channel_id: data.channel_id,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });

        connetParams(
            'customers',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (!data.error) {
                    this.setState({
                        customers: data.customers,
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    EditCampaign = () => {
        this.setState({ loading: true })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "name": this.state.name,
            "blip_id": this.state.blip_id,
            'customer_id': this.state.customer_id,
            "channel_id": this.state.channel_id
        });

        let payload = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'campaigns/' + this.state.campaign_id,
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/campaigns');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { loading, customers, name, customer_id, blip_id, channel_id } = this.state;
        console.log(customers);
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Editar campaña</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/super_assistant"}>Asistentes</Link></li>
                            <li className="breadcrumb-item active">Editar campaña</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Nombre:</label>
                                        <input type="text" value={name} onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="blip_id" className="col-form-label">Blip Id:</label>
                                        <input type="text" value={blip_id} onChange={e => this.setState({ blip_id: e.target.value })} className="form-control" id="blip_id" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="channel_id" className="col-form-label">Canal Id:</label>
                                        <input type="text" value={channel_id} onChange={e => this.setState({ channel_id: e.target.value })} className="form-control" id="channel_id" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="customer_id" className="col-form-label">Cliente:</label>
                                        <select className='form-control' value={customer_id} id="customer_id" onChange={e => this.setState({ customer_id: e.target.value })} aria-label="Default select example">
                                            <option selected>Seleccione uno</option>
                                            {
                                                customers.map(customer => {
                                                    return <option value={customer._id}>{customer.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary" onClick={this.EditCampaign} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function EditCampaign() {
    let navigate = useNavigate();
    return <Element navigate={navigate} data={useParams()} />
}

export default EditCampaign;