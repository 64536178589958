import React, { Fragment, Component } from "react";
import { connetParams, url } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_id: '',
            customers: Array(),
            from: '',
            to: '',
            conversations: false,
            conversations_len: false,
            loading: false
        }
    }
    componentDidMount() {
        let newHeaders = new Headers();
        newHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const param = {
            method: 'GET',
            headers: newHeaders,
            redirect: 'follow'
        };
        connetParams(
            'customers',
            param
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        customers: data.customers
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    download = () => {
        this.setState({
            loading: true
        })
        let newHeaders = new Headers();
        newHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const param = {
            method: 'GET',
            headers: newHeaders,
            redirect: 'follow'
        };
        connetParams(
            'invoices/' + this.state.customer_id + '?_from=' + this.state.from + ' 00:00:00' + '&to=' + this.state.to + ' 23:59:59',
            param
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        conversations_len: data.conversations_len,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
                this.setState({
                    loading: false
                })
            });
    }
    render() {
        const {
            loading,
            customers,
            conversations_len
        } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Facturas</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Facturas</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="mb-3 col-6">
                                            <label for="from" className="col-form-label">Desde:</label>
                                            <input type="date" max={new Date().toJSON().slice(0, 10)} onChange={e => this.setState({ from: e.target.value })} className="form-control" id="from" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label for="to" className="col-form-label">Hasta:</label>
                                            <input type="date" onChange={e => this.setState({ to: e.target.value })} className="form-control" id="to" />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label for="assistants" className="col-form-label">Cliente:</label>
                                        <select className="form-control" id="assistants" onChange={e => this.setState({ customer_id: e.target.value })}>
                                            <option selected>Seleccione uno</option>
                                            {
                                                customers.map(customer => {
                                                    return <option value={customer._id}>{customer.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (

                                            <a className="btn btn-primary"
                                                onClick={this.download}
                                                type="button">Buscar</a>
                                        )
                                    }
                                </div>
                                {
                                    conversations_len ?
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-delivered shadow h-100 py-2">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        Numero total de conversaciones</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{conversations_len}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-folder-open fa-2x text-gray-300"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function Invoices() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default Invoices;