import React, { Fragment, Component } from "react";
import { connetParams, connect } from "../utils/Connect";
import { setCookie } from "../utils/Cookies";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false
        }
    }
    authenticateUser = () => {
        this.setState({ loading: true })
        let payload = new URLSearchParams();
        payload.append("username", this.state.email);
        payload.append("password", this.state.password);

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        connetParams(
            'login',
            {
                method: 'POST',
                headers: myHeaders,
                body: payload,
                redirect: 'follow',
            }
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data.access_token) {
                    setCookie('token', data.access_token, 1);
                    this.props.token(data.access_token);
                } else {
                    this.setState({ loading: false });
                    alert('correo y/o contraseña incorrecta');
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { loading } = this.state;
        return (
            <Fragment>
                <div id="layoutAuthentication" className="login">
                    <div id="layoutAuthentication_content">
                        <main>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-5">
                                        <div className="card shadow-lg border-0 rounded-lg mt-5">
                                            <div className="card-header"><h3 className="text-center font-weight-light my-4">Login</h3></div>
                                            <div className="card-body">
                                                <form>
                                                    <div className="form-floating mb-3">
                                                        <input className="form-control" onChange={e => this.setState({ email: e.target.value })} id="inputEmail" type="email" placeholder="name@example.com" />
                                                        <label for="inputEmail">Email address</label>
                                                    </div>
                                                    <div className="form-floating mb-3">
                                                        <input className="form-control" id="inputPassword" onChange={e => this.setState({ password: e.target.value })} type="password" placeholder="Password" />
                                                        <label for="inputPassword">Password</label>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                                        {
                                                            loading ? (
                                                                <div className="d-flex justify-content-center col-12">
                                                                    <div className="spinner-grow text-primary" role="status">
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="d-flex justify-content-center col-12">
                                                                    <button type="button" onClick={this.authenticateUser} className="btn btn-primary" >Login</button>
                                                                </div>
                                                            
                                                            )
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <div id="layoutAuthentication_footer">
                        <footer className="py-4 bg-light mt-auto">
                            <div className="container-fluid px-4">
                                <div className="d-flex align-items-center justify-content-between small">
                                    <div className="text-muted">Copyright &copy; EmpatIA 2023</div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </Fragment>
        );
    }
}


export default Login;