import React, { Fragment, Component } from "react";
import { connetParams } from "../../../utils/Connect";
import { getCookie, eraseCookie } from "../../../utils/Cookies";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            processed: Array(),
            sent: Array(),
            delivered: Array(),
            read: Array(),
            replied: Array(),
            campaign: ''
        }
    }
    componentDidMount() {
        this.setState({
            campaign: this.props.campaign
        })
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie("token"));
        const params = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        connetParams(
            "chart/campaign?campaign_name=" + this.props.campaign + '&size=' + this.props.size,
            params
        )
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                if (data["detail"] === "Invalid credentials") {
                    eraseCookie("token");
                    this.props.navigate('/');
                }
                if (data.data != false) {
                    this.setState({
                        processed: data.processed,
                        sent: data.sent,
                        delivered: data.delivered,
                        read: data.read,
                        replied: data.replied,
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error.message);
            });

    }
    componentDidUpdate() {
        if (this.state.campaign != this.props.campaign) {
            this.setState({
                campaign: this.props.campaign,
                loading: true
            })
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + getCookie("token"));
            const params = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            connetParams(
                "chart/campaign?campaign_name=" + this.props.campaign + '&size=' + this.props.size,
                params
            )
                .then((response) => response.text())
                .then((result) => {
                    const data = JSON.parse(result);
                    if (data["detail"] === "Invalid credentials") {
                        eraseCookie("token");
                        this.props.navigate('/');
                    }
                    if (data.data != false) {
                        this.setState({
                            processed: data.processed,
                            sent: data.sent,
                            delivered: data.delivered,
                            read: data.read,
                            replied: data.replied,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    console.error(
                        "There was a problem with the fetch operation:",
                        error.message
                    );
                    eraseCookie("token");
                });

        }
    }
    render() {
        const { processed, sent, delivered, read, replied, loading } = this.state;
        const fields = processed.map(item => {
            let new_date = item.fields.$date[0].split('T')
            return new_date[0]
        });
        const dataArr = new Set(fields);
        const labels = [...dataArr].reverse();

        const fields_processed = processed.map(item => {
            let new_date = item.fields.$date[0].split('T')
            return new_date[0]
        });

        const processed_data = {}
        fields_processed.forEach(el => (processed_data[el] = processed_data[el] + 1 || 1))

        const fields_sent = sent.map(item => {
            let new_date = item.fields.$date[0].split('T')
            return new_date[0]
        });

        const sent_data = {}
        fields_sent.forEach(el => (sent_data[el] = sent_data[el] + 1 || 1))

        const fields_delivered = delivered.map(item => {
            let new_date = item.fields.$date[0].split('T')
            return new_date[0]
        });

        const delivered_data = {}
        fields_delivered.forEach(el => (delivered_data[el] = delivered_data[el] + 1 || 1))

        const fields_read = read.map(item => {
            let new_date = item.fields.$date[0].split('T')
            return new_date[0]
        });

        const read_data = {}
        fields_read.forEach(el => (read_data[el] = read_data[el] + 1 || 1))

        const fields_replied = replied.map(item => {
            let new_date = item.fields.$date[0].split('T')
            return new_date[0]
        });

        const replied_data = {}
        fields_replied.forEach(el => (replied_data[el] = replied_data[el] + 1 || 1))

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: this.props.campaing,
                },
            },
        };
        const data = {
            labels,
            datasets: [
                {
                    label: 'Procesadas',
                    data: processed_data,
                    borderColor: '#4b33fd',
                    backgroundColor: '#4b33fd',
                },
                {
                    label: 'Enviadas',
                    data: sent_data,
                    borderColor: '#6a4bfe',
                    backgroundColor: '#6a4bfe',
                },
                {
                    label: 'Recibidas',
                    data: labels.map((label) => delivered_data[label]),
                    borderColor: '#8963fe',
                    backgroundColor: '#8963fe',
                },
                {
                    label: 'Leídas',
                    data: labels.map((label) => read_data[label]),
                    borderColor: '#a87aff',
                    backgroundColor: '#a87aff',
                },
                {
                    label: 'Respondidas',
                    data: labels.map((label) => replied_data[label]),
                    borderColor: '#c792ff',
                    backgroundColor: '#c792ff',
                },
            ],
        };

        if (loading) {
            return <div className="d-grid gap-2 col-12 mx-auto">
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        }
        else return <Line options={options} data={data} />
    }
}

export default LineChart;