import React, { Fragment, Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

class DoughnutChart extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let total = this.props.likes + this.props.dont_likes;
        let likes = (this.props.likes*100)/total;
        let dont_likes = (this.props.dont_likes*100)/total;
        const data = {
            labels: ['Me gusta', 'No me gusta'],
            datasets: [
                {
                    label: '%',
                    data: [likes, dont_likes],
                    backgroundColor: [
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
        return <Doughnut data={data} />
    }
}

export default DoughnutChart;