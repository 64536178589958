import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from "../../utils/Cookies";
import LineChart from "../charts/customer/line";
import PositiveSentiment from "../charts/customer/positive_sentiment";
import NegativeSentiment from "../charts/customer/negative_sentiment";
import ConversationsDirect from "../charts/customer/conversation_direct";
import Conversions from "../charts/customer/conversions";
import ConversationsCampaign from "../charts/customer/conversation_campaign";
import Sentiment from "../charts/customer/sentiments";
import FisrtContact from "../charts/customer/first_contact";
import { Link } from "react-router-dom";
import MediaReply from "../charts/customer/media_reply";
import PositiveInsights from "../charts/customer/positive_insights";
import AbandonmentPor from "../charts/customer/abandonment_por";
import NegatveInsights from "../charts/customer/negative_insights";
import AbandonmentCategories from "../charts/customer/abandonment_categories";

class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            to: new Date().toJSON().slice(0, 10),
            from: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toJSON().slice(0, 10),
            campaigns: Array(),
            campaign: '',
            total_processed: 0,
            replied: 0,
            delivered: 0,
            size: '50'
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        const to = Date.parse(this.state.to);
        const from = Date.parse(this.state.from);
        myHeaders.append("Authorization", "Bearer " + getCookie("token"));
        const params = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        connetParams(
            "chart/campaigns?to=" + to + '&_from=' + from,
            params
        )
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                if (data["detail"] === "Invalid credentials") {
                    eraseCookie("token");
                    this.props.navigate('/');
                }
                if (data.data != false) {
                    this.setState({
                        campaigns: data.data,
                        total_processed: data.total_processed,
                        delivered: data.total_delivered,
                        replied: data.total_replied,
                        loading: false,
                        campaign: data.data[0]
                    })
                }
            })
            .catch((error) => {
                console.log(error.message);
            });
    }
    componentDidUpdate(_prevProps, prevState) {
        if (this.state.from !== prevState.from || this.state.to !== prevState.to) {
            this.setState({
                loading: true
            })
            let myHeaders = new Headers();
            const to = Date.parse(this.state.to);
            const from = Date.parse(this.state.from);
            myHeaders.append("Authorization", "Bearer " + getCookie("token"));
            const params = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            connetParams(
                "chart/campaigns?to=" + to + '&_from=' + from,
                params
            )
                .then((response) => response.text())
                .then((result) => {
                    const data = JSON.parse(result);
                    if (data["detail"] === "Invalid credentials") {
                        eraseCookie("token");
                        this.props.navigate('/');
                    }
                    if (data.data != false) {
                        this.setState({
                            campaigns: data.data,
                            total_processed: data.total_processed,
                            delivered: data.total_delivered,
                            replied: data.total_replied,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }
    }
    render() {
        const {
            campaigns,
            to,
            from,
            campaign,
            total_processed,
            replied,
            delivered,
            loading
        } = this.state;
        return (
            <Fragment>
                <main>
                    <div>
                        <form>
                            <div className="row justify-content-evenly">
                                <div className="mb-3 col-4">
                                    <label for="from" className="col-form-label">Desde:</label>
                                    <input type="date" value={from} max={new Date().toJSON().slice(0, 10)} onChange={e => this.setState({ from: e.target.value })} className="form-control" id="from" />
                                </div>
                                <div className="mb-3 col-4">
                                    <label for="to" className="col-form-label">Hasta:</label>
                                    <input type="date" value={to} onChange={e => this.setState({ to: e.target.value })} className="form-control" id="to" />
                                </div>
                            </div>
                        </form>
                        <hr></hr>
                        <div className="row">
                            <h2 className="mt-4 mb-3">Volúmenes de las campañas</h2>
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-left-primary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Total campañas procesadas</div>
                                                {
                                                    loading ?
                                                        <div className="d-grid gap-2 col-12 mx-auto">
                                                            <div className="d-flex justify-content-center">
                                                                <div className="spinner-grow text-primary" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{total_processed}</div>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-check fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-left-secundary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Total campañas recibidas</div>
                                                {
                                                    loading ?
                                                        <div className="d-grid gap-2 col-12 mx-auto">
                                                            <div className="d-flex justify-content-center">
                                                                <div className="spinner-grow text-primary" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{delivered} ({parseInt((delivered * 100) / total_processed)})%</div>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-check-double fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-left-third shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Total campañas respondidas</div>
                                                {
                                                    loading ?
                                                        <div className="d-grid gap-2 col-12 mx-auto">
                                                            <div className="d-flex justify-content-center">
                                                                <div className="spinner-grow text-primary" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{replied} ({parseInt((replied * 100) / total_processed)})%</div>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-check-double fa-2x" style={{ color: '#74C0FC' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-2 mb-3">
                            {
                                loading ?
                                    <div className="d-grid gap-2 col-12 mx-auto">
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <select className="form-control" onChange={e => this.setState({ campaign: e.target.value })} id="campaing" name="campaing" aria-label="Default select">
                                                <option selected>Selecciona uno:</option>
                                                {
                                                    campaigns.map(campaing => <option value={campaing.key}>{campaing.key}</option>)
                                                }
                                            </select>
                                            <label for="campaing">Campañas:</label>
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            campaign === '' ?
                                ''
                                :
                                <div>
                                    <LineChart campaign={campaign} size={total_processed}></LineChart>
                                </div>

                        }
                        <hr />
                        <h2 className="mt-4 mb-3">Volúmenes de las conversaciones</h2>
                        <div className="row g-2 mb-3">
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-left-primary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Conversaciones totales</div>
                                                <Conversions from={from} to={to} />
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-comments fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-left-secundary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Conversaciones directas</div>
                                                <ConversationsDirect from={from} to={to} />
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-comments fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-left-third shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Conversaciones por campaña</div>
                                                <ConversationsCampaign from={from} to={to} />
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-comments fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h2 className="mt-4 mb-3">Métricas de tiempo</h2>
                        <div className="row justify-content-evenly">
                            <div className="col-xl-5 col-md-6 mb-4">
                                <div className="card border-left-primary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Tiempo de respuesta 1ra interacción (segundos)</div>
                                                <FisrtContact from={from} to={to} />
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clock fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-md-6 mb-4">
                                <div className="card border-left-secundary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Tiempo de respuesta (segundos)</div>
                                                <MediaReply from={from} to={to} />
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clock fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h2 className="mt-4 mb-3">Métricas de calidad</h2>
                        {/* <div>
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-left-primary shadow h-100 py-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Tasa de abandono</div>
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">20%</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-door-open fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row justify-content-center">
                            <div className="col-xl-5">
                                <div className="card mb-4">
                                    <div className="card-header mb-3">
                                        <i className="fas fa-heart-pulse me-1"></i>
                                        Análisis de sentimiento
                                    </div>
                                    <div className="card-body">
                                        <Sentiment></Sentiment>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <div className="card mb-4">
                                    <div className="card-header mb-3">
                                        <div className="d-grid gap-2 d-md-flex justify-content-between">
                                            <div>
                                                <i className="fas fa-heart me-1"></i>
                                                Temas recurrentes en conversaciones positivas
                                            </div>
                                            <div>
                                                <Link
                                                    to={'/conversations/positive'}
                                                >
                                                    <i className="fa-solid fa-file-lines"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <PositiveSentiment></PositiveSentiment>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card mb-4">
                                    <div className="card-header mb-3">
                                        <div className="d-grid gap-2 d-md-flex justify-content-between">
                                            <div>
                                                <i className="fas fa-heart-crack me-1"></i>
                                                Temas recurrentes en conversaciones negativas
                                            </div>
                                            <div>
                                                <Link
                                                    to={'/conversations/negatiive'}
                                                >
                                                    <i className="fa-solid fa-file-lines"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <NegativeSentiment></NegativeSentiment>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card shadow mb-4">
                                        <div className="card-body">
                                            <p><PositiveInsights></PositiveInsights></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card shadow mb-4">
                                        <div className="card-body">
                                            <p><NegatveInsights></NegatveInsights></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="col-xl-12 col-md-12 mb-4">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                            Tasa de abandono</div>
                                                        <AbandonmentPor></AbandonmentPor>
                                                    </div>
                                                    <div className="col-auto">
                                                        <i className="fas fa-door-open fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card mb-4">
                                        <div className="card-header mb-3">
                                            <div className="d-grid gap-2 d-md-flex justify-content-between">
                                                <div>
                                                    <i className="fas fa-person-through-window me-1"></i>
                                                    Temas recurrentes en conversaciones abandonadas
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <AbandonmentCategories></AbandonmentCategories>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row justify-content-center">
                            <div className="col-xl-5">
                                <div className="card mb-4">
                                    <div className="card-header mb-3">
                                        <i className="fas fa-person-through-window me-1"></i>
                                        Temas recurrentes en conversaciones abandonadas
                                    </div>
                                    <div className="card-body">
                                        <Sentiment></Sentiment>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </main>
            </Fragment >
        )
    }
}

export default Customer;