import React, { Fragment, Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { connetParams } from "../../../utils/Connect";
import { getCookie, eraseCookie } from "../../../utils/Cookies";


ChartJS.register(ArcElement, Tooltip, Legend);

class PositiveSentiment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categories: Array(),
            total: 0
        }
    }

    componentDidMount() {
        let myHeaders = new Headers();
        const to = Date.parse(new Date().toJSON().slice(0, 10));
        myHeaders.append("Authorization", "Bearer " + getCookie("token"));
        const params = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        connetParams(
            "chart/sentiments/positive",
            params
        )
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                if (data["detail"] === "Invalid credentials") {
                    eraseCookie("token");
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        categories: data.categories,
                        total: data.total
                    })
                }
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    render() {
        const { 
            categories,
            total
        } = this.state

        let categories_names = Array();

        for (let category in categories) {
            categories_names.push(category)
        }

        const data = {
            labels: categories_names,
            datasets: [
                {
                    label: '%',
                    data: categories_names.map(name => {
                        return (categories[name].length*100)/total
                    }),
                    backgroundColor: [
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        '#e6b39a',
                        '#e6cba5',
                        '#8b9e9b',
                        '#ede3b4',
                        '#6d7578',
                        '#69d0b3',
                        '#9bdab3',
                        '#3b3b3b'
                    ],
                    borderWidth: 1,
                    outerHeight: 1,
                    innerHeight: 1,
                    innerWidth: 1,
                    outerWidth: 1,
                },
            ],
        };
        // return <Doughnut data={data} />
        return <Doughnut data={data} />
    }
}



export default PositiveSentiment;