import React, { Fragment, Component } from "react";
import { connetParams } from "../utils/Connect";
import { getCookie, eraseCookie } from "../utils/Cookies";
import Admin from "./dashboard/admin";
import Customer from "./dashboard/customer";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ccr_like: 0,
            ccr_dont_like: 0,
            ei_like: 0,
            ei_dont_like: 0,
            cas_like: 0,
            cas_dont_like: 0,
            ifd_like: 0,
            ifd_dont_like: 0,
            cgi_dont_like: 0,
            cgi_like: 0,
            likes: 0,
            dont_likes: 0,
            assistants_data: [],
            radar_data: false,
            se: [],
            amoc: [],
            user_type: false
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'user',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        user_type: data.type
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    render() {
        const {
            amoc,
            user_type
        } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Dashboard</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item">Dashboard</li>
                        </ol>
                        {
                            !user_type ?
                                <div className="d-grid gap-2 col-12 mx-auto">
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-grow text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    {
                                        user_type === 'admin' ?
                                            <Admin/>
                                            :
                                            <Customer/>
                                    }
                                </div>
                        }
                    </div>
                </main>
            </Fragment>
        )
    }
}

export default Index;