import React, { Fragment, Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie, setCookie } from '../../utils/Cookies';

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: Array()
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'user',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        user: data
                    })
                    setCookie('type', data.type, 1);
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    render() {
        const { user } = this.state;
        return (
            <Fragment>
                <div id="layoutSidenav_nav">
                    <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
                        <div className="sb-sidenav-menu">
                            <div className="nav">
                                {/* <div className="sb-sidenav-menu-heading">Core</div> */}
                                <Link className="nav-link" to={"/"}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                    Dashboard
                                </Link>
                                <Link className="nav-link" to={"/conversations"}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-comments"></i></div>
                                    Conversaciones
                                </Link>
                                <Link className="nav-link" to={"/customers_feedback"}>
                                    <div className="sb-nav-link-icon"><i className="fa-solid fa-handshake-angle"></i></div>
                                    Feedback de clientes
                                </Link>
                                {
                                    user.type === 'admin' ?
                                        <>
                                            <div className="sb-sidenav-menu-heading">Admin</div>

                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseAssistants" aria-expanded="false" aria-controls="collapseLayouts">
                                                <div className="sb-nav-link-icon"><i className="fas fa-robot"></i></div>
                                                Empatias
                                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                            </a>
                                            <div className="collapse" id="collapseAssistants" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                                <nav className="sb-sidenav-menu-nested nav">
                                                    <Link className="nav-link" to={"/super_assistant"}>
                                                        <div className="sb-nav-link-icon"><i className="fa-solid fa-users-rays"></i></div>
                                                        Asistentes
                                                    </Link>
                                                    <Link className="nav-link" to={"/tools"}>
                                                        <div className="sb-nav-link-icon"><i className="fa-solid fa-screwdriver-wrench"></i></div>
                                                        Herramientas
                                                    </Link>
                                                    <Link className="nav-link" to={"/flags"}>
                                                        <div className="sb-nav-link-icon"><i className="fa-solid fa-flag"></i></div>
                                                        Banderas Rojas
                                                    </Link>
                                                    <Link className="nav-link" to={"/documents"}>
                                                        <div className="sb-nav-link-icon"><i className="fa-solid fa-file-lines"></i></div>
                                                        Documentos
                                                    </Link>
                                                </nav>
                                            </div>
                                            <Link className="nav-link" to={"/playground"}>
                                                <div className="sb-nav-link-icon"><i className="fas fa-gamepad"></i></div>
                                                Playground
                                            </Link>
                                            <Link className="nav-link" to={"/customers"}>
                                                <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                                                Clientes
                                            </Link>
                                            <Link className="nav-link" to={"/users"}>
                                                <div className="sb-nav-link-icon"><i className="fas fa-user-group"></i></div>
                                                Usuarios
                                            </Link>
                                            <Link className="nav-link" to={"/campaigns"}>
                                                <div className="sb-nav-link-icon"><i className="fas fa-map"></i></div>
                                                Campañas
                                            </Link>
                                            <Link className="nav-link" to={"/feedback"}>
                                                <div className="sb-nav-link-icon"><i className="fa-solid fa-comment-dots"></i></div>
                                                Feedback
                                            </Link>
                                            <Link className="nav-link" to={"/invoices"}>
                                                <div className="sb-nav-link-icon"><i className="fa-solid fa-file-invoice-dollar"></i></div>
                                                Facturas
                                            </Link>
                                        </>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                        <div className="sb-sidenav-footer">
                            <div className="small">Logged in as:</div>
                            {user.name}
                        </div>
                    </nav>
                </div>
            </Fragment>
        )
    }
}

function SideNav() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default SideNav;