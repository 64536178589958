import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaigns: Array(),
            loading: true
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'campaigns',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    campaigns: data.campaign,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    delete = (id) => {
        if (window.confirm('Estas seguro que quieres eliminar a esta campaña?')) {
            this.setState({
                loading: true
            })
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + getCookie('token'));
            const params = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };
            connetParams(
                'campaigns/' + id,
                params
            ).then(response => response.text())
                .then(result => {
                    const data = JSON.parse(result);
                    if (data['detail'] === 'Invalid credentials') {
                        eraseCookie('token');
                        this.props.navigate('/');
                    }
                    this.componentDidMount();
                })
                .catch(error => {
                    console.log(error.message);
                    console.error('There was a problem with the fetch operation:', error.message);
                    this.componentDidMount();
                });
        } else {
            // Do nothing!
            console.log('cancel.');
        }
    }
    render() {
        const { campaigns, loading } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Campañas</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Campañas</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Lista de campañas
                            </div>
                            <div className="card-body">
                                <div className="d-grid gap-2 d-md-flex mb-2 justify-content-md-end">
                                    <Link
                                        to={'/campaigns/create'}
                                        className="btn btn-primary me-md-2"
                                    >
                                        Crear campaña
                                    </Link>
                                    <Link
                                        to={'/campaigns/send'}
                                        className="btn btn-primary me-md-2"
                                    >
                                        Mandar campaña
                                    </Link>
                                </div>
                                {
                                    loading ? (
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col">Blip Id</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    campaigns.map(campaign => {
                                                        return (
                                                            <tr>
                                                                <td>{campaign._id}</td>
                                                                <td>{campaign.name}</td>
                                                                <td>{campaign.customer_id}</td>
                                                                <td>{campaign.blip_id}</td>
                                                                <td>
                                                                    {/* <Link
                                                                        to={'/super_assistant/' + assistant._id}
                                                                    >
                                                                        <i className="fa-solid fa-eye m-1"></i>
                                                                    </Link> */}
                                                                    <Link
                                                                        to={'/campaigns/edit/' + campaign._id}
                                                                    >
                                                                        <i className="fa-solid fa-pen-to-square m-1"></i>
                                                                    </Link>
                                                                    <a className="link-danger" type="submit" onClick={() => this.delete(campaign._id)}><i className="fa-solid fa-trash m-1"></i></a >
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function Campaigns() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default Campaigns;