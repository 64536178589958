import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conversations: Array(),
            assistants: Array(),
            user_id: '',
            account_sid: '',
            assistant: '',
            type: getCookie('type'),
            from: '',
            to: '',
            sentiment: '',
            comment: '',
            page: 1,
            loading: true
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'conversations?page=' + this.state.page + '&user_id=' + this.state.user_id + '&assistant=' + this.state.assistant + '&_from=' + this.state.from + '&to=' + this.state.to + '&sentiment=' + this.state.sentiment + '&comment=' + this.state.comment,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        conversations: data.conversation ? data.conversation : data.conversations,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });

        myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params_new = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant',
            params_new
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        assistants: data.assistant
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    next = () => {
        const page = this.state.page;
        this.setState({
            page: page + 1,
            loading: true
        });
        this.componentDidMount();
    }
    back = () => {
        const page = this.state.page;
        if (page - 1 > 0) {
            this.setState({
                page: page - 1,
                loading: true
            });
            this.componentDidMount();
        }
    }
    search = () => {
        this.setState({
            loading: true
        });
        this.componentDidMount();
    }
    render() {
        const { conversations, loading, page, assistants, user_id, assistant, type, from, to, sentiment, comment } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Conversaciones</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Conversaciones</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Lista de conversaciones
                            </div>
                            <div className="card-body">
                                {
                                    type == 'admin' ?
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Link
                                                to={'/conversations/downloads'}
                                                className="btn btn-primary me-md-2"
                                            >
                                                Descargar conversaciones
                                            </Link>
                                        </div>
                                        :
                                        ''
                                }
                                {
                                    loading ? (
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <form>
                                                <div className="row justify-content-evenly">
                                                    <div className="col-md-3">
                                                        <label for="user_id" className="form-label">Usuario</label>
                                                        <input type="text" value={user_id} onChange={e => this.setState({ user_id: e.target.value })} className="form-control" id="user_id" />
                                                    </div>
                                                    {
                                                        type == 'admin' ?
                                                            <div className="col-md-3">
                                                                <label for="assistant" className="form-label">Asistente</label>
                                                                <select id="assistant" value={assistant} onChange={e => this.setState({ assistant: e.target.value })} className="form-select">
                                                                    <option selected>Escoge una...</option>
                                                                    {
                                                                        assistants.map(assistant => <option value={assistant._id}>{assistant.name}</option>)
                                                                    }
                                                                </select>
                                                            </div>
                                                            :
                                                            ''
                                                    }

                                                    <div className="mb-3 col-3">
                                                        <label for="from" className="col-form-label">Desde:</label>
                                                        <input type="date" value={from} max={new Date().toJSON().slice(0, 10)} onChange={e => this.setState({ from: e.target.value })} className="form-control" id="from" />
                                                    </div>
                                                    <div className="mb-3 col-3">
                                                        <label for="to" className="col-form-label">Hasta:</label>
                                                        <input type="date" value={to} onChange={e => this.setState({ to: e.target.value })} className="form-control" id="to" />
                                                    </div>
                                                    <div className="mb-3 col-3">
                                                        <label for="model" className="col-form-label">Sentimiento:</label>
                                                        <select className='form-control'
                                                            id="model"
                                                            value={sentiment}
                                                            onChange={e => this.setState({ sentiment: e.target.value })}
                                                            aria-label="Default select example">
                                                            <option value={''} selected>Seleccione uno</option>
                                                            <option value={'positive'}>Positivo</option>
                                                            <option value={'negative'}>Negativo</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-evenly">
                                                    <div className="col-3">
                                                        <button type="button" onClick={this.search} className="mt-4 btn btn-primary">Buscar</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <hr />
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Usuario</th>
                                                        {
                                                            type == 'admin' ?
                                                                <th scope="col">Asistente</th>
                                                                :
                                                                ''
                                                        }
                                                        <th scope="col">Tipo</th>
                                                        <th scope="col">Cuenta</th>
                                                        <th scope="col">Creado</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        conversations.map(conversation => {
                                                            return (
                                                                <tr>
                                                                    <td>{conversation.number}</td>
                                                                    {assistants.map(assistant => {
                                                                        if (assistant._id == conversation.assistant_id) {
                                                                            return <td> {assistant.name}</td>
                                                                        }
                                                                    })}
                                                                    <td>{conversation.type}</td>
                                                                    <td>{conversation.ticket}</td>
                                                                    <td>{conversation.created_at}</td>
                                                                    <td>
                                                                        <Link
                                                                            to={'/conversation/' + conversation._id}
                                                                        >
                                                                            <i className="fa-solid fa-eye m-1"></i>
                                                                        </Link>
                                                                        {/* <a className="link-danger" type="submit" onClick={() => deleteAssistant(conversation.user_id)}><i className="fa-solid fa-trash m-1"></i></a > */}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>

                                                {
                                                    user_id != '' ? '' :
                                                        <nav aria-label="Page navigation example">
                                                            <ul className="pagination">
                                                                <li className="page-item">
                                                                    {
                                                                        page <= 1 ? (
                                                                            <a className="page-link disabled" aria-label="Previous">
                                                                                <span aria-hidden="true">&laquo;</span>
                                                                            </a>
                                                                        ) :
                                                                            (

                                                                                <a className="page-link" onClick={this.back} aria-label="Previous">
                                                                                    <span aria-hidden="true">&laquo;</span>
                                                                                </a>
                                                                            )
                                                                    }
                                                                </li>
                                                                <li className="page-item">
                                                                    <a className="page-link" onClick={this.next} aria-label="Next">
                                                                        <span aria-hidden="true">&raquo;</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                }
                                            </table>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function Conversations() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default Conversations;