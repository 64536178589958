import React, { Fragment, Component } from "react";
import { connetParams, url } from "../../../utils/Connect";
import { useParams } from "react-router-dom";
import { eraseCookie, getCookie } from "../../../utils/Cookies";
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index_id: this.props.data.index_id,
            type: '',
            file: '',
            url: '',
            name: '',
            space_key: '',
            space_key: '',
            api_key: '',
            loading: false
        }
    }
    upload_docs = () => {
        this.setState({
            loading: true
        });
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const formdata = new FormData();
        formdata.append("file", this.state.file);

        let payload = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let params = '?index_id=' + this.state.index_id + '&type=' + this.state.type;

        if (this.state.type == 'url' || this.state.type == 'youtube' || this.state.type == 'cofluence') {
            params += '&url=' + this.state.url ;
        }
        if (this.state.type == 'cofluence') {
            params += '&name=' + this.state.name + '&space_key=' + this.state.space_key + '&api_key=' + this.state.api_key;
        }

        connetParams(
            'embeddings/docs' + params,
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.props.navigate('/documents/index/' + this.state.index_id);
                }
                else {
                    alert('Error al subir archivo');
                }
                this.setState({ loading: false });
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { loading, type } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Subir documento</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/documents"}>Documentos</Link></li>
                            <li className="breadcrumb-item active">Subir documento</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="model" className="col-form-label">Tipo:</label>
                                        <select className='form-control'
                                            id="type"
                                            onChange={e => this.setState({ type: e.target.value })}
                                            aria-label="Default select example">
                                            <option selected>Seleccione uno</option>
                                            <option value={'youtube'}>youtube</option>
                                            <option value={'doc'}>Documentos</option>
                                            <option value={'url'}>URL</option>
                                            <option value={'cofluence'}>Cofluence</option>
                                        </select>
                                    </div>
                                    {
                                        type === 'doc' ?
                                            <div className="mb-3">
                                                <label for="name" className="col-form-label">File:</label>
                                                <input type="file" onChange={e => this.setState({ file: e.target.files[0] })} className="form-control" id="name" />
                                            </div> : ''
                                    }
                                    {
                                        type === 'youtube' ?
                                            <div className="mb-3">
                                                <label for="url" className="col-form-label">URL de youtube:</label>
                                                <input type="text" onChange={e => this.setState({ url: e.target.value })} className="form-control" id="url" />
                                            </div> : ''
                                    }
                                    {
                                        type === 'url' ?
                                            <div className="mb-3">
                                                <label for="url" className="col-form-label">URL:</label>
                                                <input type="text" onChange={e => this.setState({ url: e.target.value })} className="form-control" id="url" />
                                            </div> : ''
                                    }
                                    {
                                        type === 'cofluence' ?
                                            <>
                                                <div className="row">
                                                    <div className="mb-3 col-6">
                                                        <label for="url" className="col-form-label">URL:</label>
                                                        <input type="text" onChange={e => this.setState({ url: e.target.value })} className="form-control" id="url" />
                                                    </div>
                                                    <div className="mb-3 col-6">
                                                        <label for="name" className="col-form-label">Username:</label>
                                                        <input type="text" onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                                    </div>
                                                    <div className="mb-3 col-6">
                                                        <label for="space_key" className="col-form-label">Space key:</label>
                                                        <input type="text" onChange={e => this.setState({ space_key: e.target.value })} className="form-control" id="space_key" />
                                                    </div>
                                                    <div className="mb-3 col-6">
                                                        <label for="api_key" className="col-form-label">Api key:</label>
                                                        <input type="text" onChange={e => this.setState({ api_key: e.target.value })} className="form-control" id="api_key" />
                                                    </div>
                                                </div>
                                            </> : ''
                                    }
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary" onClick={this.upload_docs} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function UploadDoc() {
    let navigate = useNavigate();
    return <Element navigate={navigate} data={useParams()} />
}

export default UploadDoc;