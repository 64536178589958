import React, { Fragment, Component } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

class BarCharts extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                // title: {
                //     display: true,
                //     text: 'Chart.js Bar Chart',
                // },
            },
        };
        const labels = ['CCR', 'EI', 'CAS', 'IFD', 'CGI'];
        const data = {
            labels,
            datasets: [
                {
                    label: 'Me gusta',
                    data: {
                        'CCR': this.props.ccr_like,
                        'EI': this.props.ei_like,
                        'CAS': this.props.cas_like,
                        'IFD': this.props.ifd_like,
                        'CGI': this.props.cgi_like
                    },
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    label: 'No me gusta',
                    data: {
                        'CCR': this.props.ccr_dont_like,
                        'EI': this.props.ei_dont_like,
                        'CAS': this.props.cas_dont_like,
                        'IFD': this.props.ifd_dont_like,
                        'CGI': this.props.cgi_dont_like
                    },
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        };
        return <Bar options={options} data={data} />
    }
}

export default BarCharts;