import React, { Fragment, Component } from "react";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';



ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

class RadarChart extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let positive =[]
        let negative = []
        if (this.props.id) {
            let data = []
            this.props.data.map(item => {
                if(item.id === this.props.id){
                    data = item
                }
            })
            positive = [data.like.ccr, data.like.ei, data.like.cas, data.like.ifd, data.like.cgi];
            negative = [data.dont_like.ccr, data.dont_like.ei, data.dont_like.cas, data.dont_like.ifd, data.dont_like.cgi];
        }
        else {
            positive = [0, 0, 0, 0, 0];
            negative = [0, 0, 0, 0, 0];
        }

        const data = {
            labels: ['CCR', 'EI', 'CAS', 'IFD', 'CGI'],
            datasets: [
                {
                    label: 'Positivo',
                    data: positive,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    borderColor: 'blue',
                    borderWidth: 1,
                },
                {
                    label: 'Negativo',
                    data: negative,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ],
        };
        return <Radar data={data} />;
    }
}

export default RadarChart;