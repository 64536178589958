import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";

class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: Array(),
            page: 1,
            loading: true
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'customer_feedback?page=' + this.state.page,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        feedback: data.feedback,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    next = () => {
        const page = this.state.page;
        this.setState({
            page: page + 1,
            loading: true
        });
        this.componentDidMount();
    }
    back = () => {
        const page = this.state.page;
        if (page - 1 > 0) {
            this.setState({
                page: page - 1,
                loading: true
            });
            this.componentDidMount();
        }
    }
    render() {
        const { feedback, loading, page } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Feedback de cliente</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Feedback de cliente</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Feedback
                            </div>
                            <div className="card-body">
                                {
                                    loading ? (
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col">Tipo</th>
                                                    <th scope="col">Comentario</th>
                                                    <th scope="col">Fecha</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    feedback.map(feed => {
                                                        return (
                                                            <tr>
                                                                <td>{feed.customer_id}</td>
                                                                <td><p style={{ 'text-align': 'center' }}>{feed.rate === 'like' ? <i className="fa-solid fa-heart"></i> : ''}{feed.rate === 'dont_like' ? <i className="fa-solid fa-heart-crack"></i> : ''}{!feed.rate ? <i className="fa-solid fa-circle-xmark"></i> : ''}</p></td>
                                                                <td>{
                                                                    feed.comment || feed.comment !== '' ?
                                                                    feed.comment
                                                                    :
                                                                    <i className="fa-solid fa-circle-xmark"></i>
                                                                }</td>
                                                                <td>{feed.created_at}</td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>

                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        {
                                                            page <= 1 ? (
                                                                <a className="page-link disabled" aria-label="Previous">
                                                                    <span aria-hidden="true">&laquo;</span>
                                                                </a>
                                                            ) :
                                                                (

                                                                    <a className="page-link" onClick={this.back} aria-label="Previous">
                                                                        <span aria-hidden="true">&laquo;</span>
                                                                    </a>
                                                                )
                                                        }
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" onClick={this.next} aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </table>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

function CustomerFeedback() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default CustomerFeedback;