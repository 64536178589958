import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";
import UserMessage from "../layouts/user_message";
import AssistantMessage from "../layouts/assistant_message";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: Array(),
            assistants: Array(),
            new_message: '',
            flags: Array(),
            flag_id: '',
            tools: Array(),
            assistant_id: '',
            tools_id: Array(),
            loading: false
        }
    }
    componentDidMount() {
        let newHeaders = new Headers();
        newHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const param = {
            method: 'GET',
            headers: newHeaders,
            redirect: 'follow'
        };
        connetParams(
            'flags?all=' + true,
            param
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        flags: data.flags,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
        connetParams(
            'tools',
            param
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.setState({
                    tools: data.tools,
                })
            })
            .catch(error => {
                console.log(error.message);
            });
        connetParams(
            'super_assistant',
            param
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        assistants: data.assistant
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    send_message = () => {
        this.setState({ loading: true });
        let today = new Date();

        let myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        let messages = this.state.messages;

        messages.push(
            {
                'role': 'user',
                'content': this.state.new_message,
                'date': today.toLocaleTimeString('en-US')
            }
        );

        const raw = JSON.stringify({
            "messages": messages.map(message => {
                if (!message['error']) {
                    return message;
                }
            }),
            "tools": this.state.tools_id,
            "assistant_id": this.state.assistant_id,
            "new_message": this.state.new_message,
            "flag_id": this.state.flag_id
        });

        this.setState({ messages: messages, new_message: '' });

        let payload = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'chat/playground',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    let messages = this.state.messages;
                    if (data.is_red_flag) {
                        messages.push(
                            {
                                'role': 'assistant',
                                'content': <><i style={{ color: 'red' }} className="fa-solid fa-flag"></i>{data.message}</>,
                                'date': today.toLocaleTimeString('en-US'),
                                'error': true
                            }
                        );
                    }
                    else {
                        messages.push(
                            {
                                'role': 'assistant',
                                'content': data.message,
                                'date': today.toLocaleTimeString('en-US')
                            }
                        );
                    }
                    console.log(data.justification);
                    this.setState({ messages: messages, loading: false });
                }
                else {
                    let messages = this.state.messages;
                    messages.push(
                        {
                            'role': 'assistant',
                            'content': <><i style={{ color: 'red' }} className="fa-solid fa-skull-crossbones"></i> error al procesar el mensaje</>,
                            'date': today.toLocaleTimeString('en-US'),
                            'error': true
                        }
                    );
                    this.setState({ messages: messages, loading: false });
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    reset_conversation = () => {
        this.setState({
            messages: Array()
        })
    }
    render() {
        const { messages, new_message, assistants, tools_id, tools, loading, flags } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Playground</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Playground</li>
                        </ol>

                        <div className="row">
                            <div className="col-xl-4 mb-4">
                                <div className="card">
                                    <div className="card-body">
                                        <form>
                                            <div className="mb-3">
                                                <label for="assistants" className="col-form-label">Asistente:</label>
                                                <select className="form-control" id="assistants" onChange={e => this.setState({ assistant_id: e.target.value })}>
                                                    <option selected>Seleccione uno</option>
                                                    {
                                                        assistants.map(assistant => {
                                                            return <option value={assistant._id}>{assistant.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label for="assistants" className="col-form-label">Banderas rojas:</label>
                                                <select className="form-control" id="assistants" onChange={e => this.setState({ flag_id: e.target.value })}>
                                                    <option selected>Seleccione uno</option>
                                                    {
                                                        flags.map(flag => {
                                                            return <option value={flag._id}>{flag.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label for="tools" className="col-form-label">Herramientas:</label>
                                                <p></p>
                                                {
                                                    tools.map(tool => {
                                                        return <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="checkbox" onChange={e => {
                                                                const value = e.target.value;
                                                                let tools = tools_id;
                                                                const index = tools.indexOf(value);
                                                                if (index > -1) {
                                                                    tools.splice(index, 1);
                                                                }
                                                                else {
                                                                    tools.push(value)
                                                                }
                                                                this.setState({ tools_id: tools })
                                                            }} id={tool._id} value={tool._id} />
                                                            <label className="form-check-label" for={tool._id}>{tool.name}</label>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </form>
                                        <hr></hr>
                                        <div className="d-grid gap-2 col-8 mx-auto">
                                            <button className="btn btn-primary" onClick={this.reset_conversation} type="button">Reiniciar conversacion</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 mb-3">
                                <section className="msger">
                                    <header className="msger-header">
                                        <div className="msger-header-title">
                                            <i className="fas fa-comment-alt"></i> Chat
                                        </div>
                                    </header>

                                    <main className="msger-chat">
                                        {
                                            messages.map(message => {
                                                if (message.role == 'user') {
                                                    return <UserMessage message={message.content} date={message.date.toString()} />
                                                }
                                                else {
                                                    return <AssistantMessage message={message.content} date={message.date.toString()} />
                                                }
                                            })
                                        }
                                    </main>

                                    <div className="msger-inputarea">
                                        <input type="text" value={new_message} className="msger-input" onChange={e => this.setState({ new_message: e.target.value })} placeholder="Ingresa el mensaje..." />
                                        {
                                            loading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-grow text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <button type="submit" onClick={this.send_message} className="msger-send-btn">Enviar</button>
                                            )
                                        }
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}


function Playground() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default Playground;