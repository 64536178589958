import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag_id: this.props.data.flag_id,
            customer_id: '',
            model: '',
            name: '',
            prompt: '',
            email: '',
            customers: Array(),
            loading: true
        }
    }
    componentDidMount() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const params = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        connetParams(
            'customers',
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (!data.error) {
                    this.setState({
                        customers: data.customers,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
        connetParams(
            'flags/' + this.state.flag_id,
            params
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (!data.error) {
                    this.setState({
                        prompt: data.prompt,
                        email: data.email,
                        name: data.name,
                        customer_id: data.customer_id,
                        doc_id: data.doc_id,
                        prompt: data.prompt,
                        model: data.model,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    update_flag = () => {
        this.setState({ loading: true })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "name": this.state.name,
            "prompt": this.state.prompt,
            "model": this.state.model,
            "customer_id": this.state.customer_id,
            "email": this.state.email
        });

        let payload = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'flags/' + this.state.flag_id,
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/flags');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const {
            loading,
            customers,
            name,
            email,
            prompt,
            model,
            customer_id
        } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Editar bandera</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/flags"}>Banderas rojas</Link></li>
                            <li className="breadcrumb-item active">Editar bandera</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="mb-3 col-6">
                                            <label for="name" className="col-form-label">Nombre:</label>
                                            <input type="text"
                                                value={name}
                                                onChange={e => this.setState({ name: e.target.value })}
                                                className="form-control" id="name" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label for="name" className="col-form-label">Correo de notificaciones:</label>
                                            <input type="text"
                                                value={email}
                                                onChange={e => this.setState({ email: e.target.value })}
                                                className="form-control" id="name" />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label for="prompt" className="col-form-label">Prompt:</label>
                                        <textarea className="form-control" id="prompt"
                                            value={prompt}
                                            onChange={e => this.setState({ prompt: e.target.value })}
                                            rows="15"></textarea>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-6">
                                            <label for="model" className="col-form-label">Modelo:</label>
                                            <select className='form-control'
                                                value={model}
                                                id="model"
                                                onChange={e => this.setState({ model: e.target.value })}
                                                aria-label="Default select example">
                                                <option selected>Seleccione uno</option>
                                                <option value={'gpt-4o'}>gpt-4o</option>
                                                <option value={'gpt-4-turbo-preview'}>gpt-4-turbo-preview</option>
                                                <option value={'gpt-3.5-turbo-0125'}>gpt-3.5-turbo-0125</option>
                                            </select>
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label for="customer_id" className="col-form-label">Cliente:</label>
                                            <select className='form-control' id="customer_id"
                                                value={customer_id}
                                                onChange={e => this.setState({ customer_id: e.target.value })}
                                                aria-label="Default select example">
                                                <option selected>Seleccione uno</option>
                                                {
                                                    customers.map(customer => {
                                                        return <option value={customer._id}>{customer.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary"
                                                onClick={this.update_flag} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function EditFLag() {
    let navigate = useNavigate();
    return <Element navigate={navigate} data={useParams()} />
}

export default EditFLag;