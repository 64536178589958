import React, { Component } from "react";
import { connetParams } from "../../utils/Connect";
import { getCookie, eraseCookie } from "../../utils/Cookies";
import { useParams } from "react-router-dom";

class UserMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message,
            date: this.props.date
        }
    }
    render() {
        const { message, date } = this.state;

        return <div className="msg right-msg">

            <div className="msg-bubble">
                <div className="msg-info">
                    <div className="msg-info-name">User</div>
                    <div className="msg-info-time">{date}</div>
                </div>

                <div className="msg-text">
                    {message}
                </div>
            </div>
        </div>
    }
}

export default UserMessage;