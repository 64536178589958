import React, { Fragment, Component } from "react";
import { connetParams } from "../../utils/Connect";
import { eraseCookie, getCookie } from '../../utils/Cookies';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';


class Element extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            id: uuidv4(),
            auth_intercom: '',
            assistant_id: '',
            context: '',
            gus_user: '',
            gus_key: '',
            loading: false,
            feedback: false,
            assistants: Array()
        }
    }
    componentDidMount() {
        let newHeaders = new Headers();
        newHeaders.append("Authorization", "Bearer " + getCookie('token'));
        const param = {
            method: 'GET',
            headers: newHeaders,
            redirect: 'follow'
        };
        connetParams(
            'super_assistant',
            param
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                if (!data.error) {
                    this.setState({
                        assistants: data.assistant
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }
    createAssistant = () => {
        this.setState({ loading: true })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getCookie('token'));

        const raw = JSON.stringify({
            "name": this.state.name,
            'auth_intercom': this.state.auth_intercom,
            'assistant_id': this.state.assistant_id,
            'feedback': this.state.feedback,
            'gus_key': this.state.gus_key,
            'gus_user': this.state.gus_user,
            'context': this.state.context
        });

        let payload = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        connetParams(
            'customer',
            payload
        ).then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data['detail'] === 'Invalid credentials') {
                    eraseCookie('token');
                    this.props.navigate('/');
                }
                this.props.navigate('/customers');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
            });
    }
    render() {
        const { loading, assistants, feedback } = this.state;
        return (
            <Fragment>
                <main>
                    <div className="container-fluid px-4">
                        <h1 className="mt-4">Crear clientes</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                            <li className="breadcrumb-item "><Link to={"/customers"}>Clientes</Link></li>
                            <li className="breadcrumb-item active">Crear clientes</li>
                        </ol>
                        <div className="card mb-4">
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Nombre:</label>
                                        <input type="text" onChange={e => this.setState({ name: e.target.value })} className="form-control" id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="auth_intercom" className="col-form-label">Auth Intercom:</label>
                                        <input type="text" onChange={e => this.setState({ auth_intercom: e.target.value })} className="form-control" id="auth_intercom" />
                                    </div>
                                    <div className="mb-3">
                                        <label for="context" className="col-form-label">Contexto:</label>
                                        <textarea className="form-control" id="context"
                                            onChange={e => this.setState({ context: e.target.value })}
                                            rows="15"></textarea>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <label for="name" className="col-form-label">Gus user:</label>
                                            <input type="text" onChange={e => this.setState({ gus_user: e.target.value })} className="form-control" id="name" />
                                        </div>
                                        <div className="col-6">
                                            <label for="name" className="col-form-label">Gus key:</label>
                                            <input type="text" onChange={e => this.setState({ gus_key: e.target.value })} className="form-control" id="name" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                            <label for="feedback" className="col-form-label">Feedback:</label>
                                            <select className="form-control" value={feedback} id="feedback" onChange={e => this.setState({ feedback: e.target.value })}>
                                                <option selected>Seleccione uno</option>
                                                <option value={true}>Activado</option>
                                                <option value={false}>Desactivado</option>
                                            </select>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <label for="assistants" className="col-form-label">Asistente:</label>
                                            <select className="form-control" id="assistants" onChange={e => this.setState({ assistant_id: e.target.value })}>
                                                <option selected>Seleccione uno</option>
                                                {
                                                    assistants.map(assistant => {
                                                        return <option value={assistant._id}>{assistant.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </form>
                                <hr></hr>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {
                                        loading ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-grow text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <button className="btn btn-primary" onClick={this.createAssistant} type="button">Guardar</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }

}

function CreateCustomer() {
    let navigate = useNavigate();
    return <Element navigate={navigate} />
}

export default CreateCustomer;